
// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

//require("@rails/activestorage").start()
import "@hotwired/stimulus"
//import "leaflet"
//import "./trailmaps.js"
//import "dropzone"
//import "./controllers"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
